import React from "react"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import styled from "styled-components"

import Layout, { Section } from "../layout"
import SEO from "../seo"
import DeviceImage from "../images/device/Device"
import HornImage from "../images/horn/Horn"
import SilbatoMobileAppImage from "../images/SilbatoMobileAppImage"
import Columns, { Column } from "../Columns"
import InstallerBanner from "../InstallerBanner"
import FreeQuoteCTA from "../FreeQuoteCTA"
import Features from "../Features"
import { LIGHT_GREY } from "../../constants/colors"

const CardWrapper = styled.div`
  background: white;

  display: flex;
  flex-direction: column;
  padding: 16px;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;

  h5 {
    text-align: center;
  }

  > p {
    margin-top: 1em;
    min-height: 45px;
    margin-bottom: 1em;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin-bottom: 0;
    }
  }
`

interface ICardProps {
  title: string
  description: string
  price: string
  learnMoreUrl: string
  image: any
}
const Card = ({
  title,
  description,
  image,
  price,
  learnMoreUrl,
}: ICardProps) => {
  return (
    <CardWrapper>
      <h5>{title}</h5>
      {image}
      <p>{description}</p>
      <div>
        <p style={{ fontWeight: "bold" }}>{price}</p>
        <a href={learnMoreUrl}>
          <FormattedMessage id="pricing.learnMore" />
        </a>
      </div>
    </CardWrapper>
  )
}

export default function Pricing() {
  const intl = useIntl()

  return (
    <Layout>
      <SEO lang={intl.locale} title={intl.formatMessage({ id: "title" })} />

      <Section>
        <h2 className="text-center">
          <FormattedMessage id="pricing.title" />
        </h2>
        <p className="text-center">
          <FormattedMessage id="pricing.description" />
        </p>
      </Section>

      <div style={{ background: LIGHT_GREY }}>
        <Section>
          <h4 className="text-center">
            <FormattedMessage id="pricing.hardware" />
          </h4>
          <p className="text-center">
            <FormattedMessage id="pricing.hardwareDescription" />
          </p>

          <Columns>
            <Column>
              <Card
                title={intl.formatMessage({ id: "pricing.horn" })}
                description={intl.formatMessage({
                  id: "pricing.hornDescription",
                })}
                price={intl.formatMessage({ id: "pricing.hornPrice" })}
                image={<HornImage />}
                learnMoreUrl={intl.formatMessage({ id: "pages.horn" })}
              />
            </Column>
            <Column>
              <Card
                title={intl.formatMessage({ id: "pricing.device" })}
                description={intl.formatMessage({
                  id: "pricing.deviceDescription",
                })}
                price={intl.formatMessage({ id: "pricing.devicePrice" })}
                image={<DeviceImage />}
                learnMoreUrl={intl.formatMessage({ id: "pages.device" })}
              />
            </Column>
          </Columns>
        </Section>
      </div>

      <Section>
        <h4 className="text-center">
          <FormattedMessage id="pricing.software" />
        </h4>
        <p className="text-center">
          <FormattedMessage id="pricing.softwareDescription" />
        </p>
        <br />
        <Columns noPadding>
          <Column>
            <SilbatoMobileAppImage />
          </Column>
          <Column style={{ alignSelf: "center" }}>
            <Features
              features={[
                intl.formatMessage({ id: "pricing.softwareFeatureAdvanced" }),
                intl.formatMessage({
                  id: "pricing.softwareFeaturePersonalized",
                }),
                intl.formatMessage({ id: "pricing.softwareFeatureReliable" }),
                intl.formatMessage({ id: "pricing.softwareFeatureEasy" }),
                intl.formatMessage({ id: "pricing.softwareFeatureSounds" }),
              ]}
            />
          </Column>
        </Columns>
      </Section>

      <Section>
        <p className="text-center">
          <FormattedMessage id="pricing.subscription" />
        </p>
        <FreeQuoteCTA />
      </Section>

      <Section>
        <InstallerBanner />
      </Section>
    </Layout>
  )
}
